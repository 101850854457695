import React from 'react';
import styled from 'styled-components';

import { useNavigate, useParams } from 'react-router-dom';

import { useFormContext } from '../context/context';
import { Spinner } from '../components';

const DataInput = () => {
  const {
    handleChange,
    handleSubmit,
    formData,
    editMode,
    checkMounted,
    isLoading,
    handleUpdateForm,
    isDisable,
    setIsDisable,
  } = useFormContext();

  const navigate = useNavigate();
  const params = useParams();
  const datePicker = new Date().toISOString().split('T')[0];
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper className='section newUser sharedPage'>
      <h2>{!editMode ? 'Add New Lead' : 'Edit Lead Info'}</h2>
      <hr />
      <section>
        <h4 className='title'>Lead Information</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsDisable(true);
            if (editMode) {
              handleUpdateForm(params.id);
            } else {
              handleSubmit();
            }
          }}
        >
          <div className='Form'>
            <div className='form-control'>
              <label htmlFor='title'>ID</label>
              <input
                type='text'
                name='id'
                onChange={handleChange}
                value={formData.id}
                required
                disabled
              />
            </div>
            <div className='form-control'>
              <label htmlFor='title'>Title</label>
              <input
                type='text'
                name='title'
                onChange={handleChange}
                value={formData.title}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='position'>Position</label>
              <input
                type='text'
                name='position'
                onChange={handleChange}
                value={formData.position}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='name'>Name</label>
              <input
                type='text'
                name='name'
                onChange={handleChange}
                value={formData.name}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='phone'>Phone</label>
              <input
                type='number'
                name='phone'
                onChange={handleChange}
                value={formData.phone}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='email'>Email</label>
              <input
                type='email'
                name='email'
                onChange={handleChange}
                value={formData.email}
                required
              />
            </div>

            <div className='form-control'>
              <label htmlFor='company'>company</label>
              <input
                type='text'
                name='company'
                onChange={handleChange}
                value={formData.company}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='status'>Status</label>
              <select
                type='text'
                name='status'
                onChange={handleChange}
                value={formData.status}
                required
              >
                <option value='not contacted'>Open - Not Contacted</option>
                <option value='contacted'>Working - Contacted</option>
                <option value='pitching'>Pitching</option>
                <option value='converted'>Closed - Converted</option>
                <option value='not converted'>Closed - Not Converted</option>
              </select>
            </div>

            <div className='c-prefrence'>
              <p className='label'>Contact Prefrence</p>
              <div className='radio-input'>
                <div>
                  <input
                    type='radio'
                    name='contact_prefrence'
                    onChange={handleChange}
                    value='phone'
                    // value={formData.contact_prefrence}
                    required
                    checked={formData.contact_prefrence === 'phone'}
                  />
                  <label htmlFor='contact_prefrence'>Phone</label>
                </div>
                <div>
                  <input
                    type='radio'
                    name='contact_prefrence'
                    onChange={handleChange}
                    value='E-mail'
                    // value={formData.contact_prefrence}
                    required
                    checked={formData.contact_prefrence === 'E-mail'}
                  />
                  <label htmlFor='contact_prefrence'>E-mail</label>
                </div>
                <div>
                  <input
                    type='radio'
                    name='contact_prefrence'
                    onChange={handleChange}
                    value='Text'
                    // value={formData.contact_prefrence}
                    required
                    checked={formData.contact_prefrence === 'Text'}
                  />
                  <label htmlFor='contact_prefrence'>Text</label>
                </div>
                <div>
                  <input
                    type='radio'
                    name='contact_prefrence'
                    onChange={handleChange}
                    value='No Prefrence'
                    // value={formData.contact_prefrence}
                    required
                    checked={formData.contact_prefrence === 'No Prefrence'}
                  />
                  <label htmlFor='contact_prefrence'>No prefrence</label>
                </div>
              </div>
            </div>

            <div className='form-control'>
              <label htmlFor='progress'>Progress</label>
              <input
                type='range'
                name='progress'
                onChange={handleChange}
                value={formData.progress}
                required
                min='0'
                max='100'
                className='progressInput'
              />
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {formData.progress}%
              </span>
            </div>

            <div className='form-control'>
              <label htmlFor='address'>Address</label>
              <input
                type='address'
                name='address'
                onChange={handleChange}
                value={formData.address}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='category'>Category</label>
              <select
                type='text'
                name='category'
                onChange={handleChange}
                value={formData.category}
                required
              >
                <option value='High Networth'>High Networth</option>
                <option value='Middle Class'>Middle Class</option>
                <option value='Average'>Average</option>
              </select>
            </div>
            <div className='form-control'>
              <label htmlFor='priority'>Priority</label>
              <input
                type='number'
                name='priority'
                onChange={handleChange}
                value={formData.priority}
                required
                min='0'
                max='5'
              />
            </div>
            <div className='form-control'>
              <label htmlFor='follow_up'>Follow Up Date</label>
              <input
                type='date'
                name='follow_up'
                onChange={handleChange}
                value={formData.follow_up}
                min={datePicker}
                max='2023-12-31'
                required
              />
            </div>
            {/* <div className='form-control'>
              <label htmlFor='owner'>Owner</label>
              <input
                type='text'
                name='owner'
                onChange={handleChange}
                value={formData.owner}
                required
              />
            </div> */}

            <div className='form-control'>
              <label htmlFor='date'>Date Created</label>
              <input
                type='text'
                name='date'
                onChange={handleChange}
                value={formData.timestamp}
                required
                disabled
              />
            </div>

            <div className='form-control text-area'>
              <label htmlFor='description'>Notes</label>
              <textarea
                name='description'
                id='description'
                cols='30'
                rows='5'
                value={formData.description}
                onChange={handleChange}
                placeholder='Give an update'
                required
              />
            </div>
          </div>
          <div className='btn-container'>
            <button
              disabled={isDisable}
              type='submit'
              className='btn'
              // onClick={() => {
              //   setIsDisable(true);
              //   if (editMode) {
              //     handleUpdateForm(params.id);
              //   } else {
              //     handleSubmit();
              //   }
              // }}
            >
              {!editMode ? 'SAVE' : 'UPDATE'}
            </button>
          </div>
        </form>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  overflow: scroll;
  height: 100vh;
  width: 100%;

  .title {
    background: var(--clr-grey-9);
    padding: 0.5rem;

    margin-top: 10px;
    width: 100%;
  }

  .newUser {
    flex: 4;
  }

  .Form {
    display: flex;
    flex-wrap: wrap;

    justify-content: center;
  }

  .form-control {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
  }
  .c-prefrence {
    width: 25rem;

    .label {
      color: rgb(151, 150, 150);

      margin: 10px 0 10px 0;
    }
    label {
      margin-left: 5px;
    }
  }
  .radio-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .form-control > label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(151, 150, 150);
  }

  .form-control > input {
    height: 40px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  .form-control > .progressInput {
    padding: 0 !important;
  }
  .newUserGender > input {
    margin-top: 15px;
  }

  .newUserGender > label {
    margin: 10px;
    font-size: 18px;
    color: #555;
  }

  .form-control select {
    height: 40px;
    border-radius: 5px;
  }
  .btn-container {
    width: 90%;
    min-height: 2rem;
    display: grid;
    place-items: center;
    margin: 0 auto;
    max-width: 450px;
  }
  button {
    margin-top: 20px;
    max-height: 3rem;
    display: block;
    width: 100%;
  }
`;
export default DataInput;
