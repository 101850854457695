import React from 'react';

import ReactFC from 'react-fusioncharts';

import FusionCharts from 'fusioncharts';

import Doughnut2D from 'fusioncharts/fusioncharts.charts';

import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.candy';

ReactFC.fcRoot(FusionCharts, Doughnut2D, FusionTheme);

const DoughnutChart = ({ data }) => {
  const chartConfigs = {
    type: 'Doughnut3D',
    width: '100%',
    height: '400',
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: 'Account officers with most converted Leads',
        theme: 'candy',
        decimals: 0,
        doughnutRadius: '30%',
        // valuePosition: 'inside',
        smartLineColor: '#ff0000',
        smartLineThickness: '2',
        useDataPlotColorForLabels: '1',
        labelFontSize: '20px',
        // paletteColors: '#ffd700,#ffff00,#ff9000,#ff0000,#00ff00',
        // paletteColors: '#ffd700,#ffff00,#ff9000,#ff0000,#00ff00'
      },

      data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};
export default DoughnutChart;
