import {
  FETCH_CLIENTS,
  FETCH_CUSTOMERS,
  NEW_ORDERS,
  GET_ORDERS_BEGIN,
  GET_ORDERS_SUCCESS,
  IS_LOADING,
  DONE_LOADING,
  ADD_CLIENT,
  ON_SUBMIT,
  REP_CUSTOMER,
  CLEAR_FORM,
} from '../actions';
import { serverTimestamp } from 'firebase/firestore';

// const months = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];
// const getMonth = new Date();

const clientsReducer = (state, action) => {
  if (action.type === IS_LOADING) {
    return { ...state, isLoading: true };
  }

  if (action.type === FETCH_CUSTOMERS) {
    const querySnap = action.payload;
    const all_customers = [];

    querySnap.forEach((doc) => {
      const newData = { ...doc.data(), id: doc.id };
      all_customers.push(newData);
    });

    return { ...state, all_orders: all_customers };
  }

  if (action.type === REP_CUSTOMER) {
    const querySnap = action.payload;
    const customers = [];

    querySnap.forEach((doc) => {
      const newData = { ...doc.data(), id: doc.id };
      customers.push(newData);
    });
    return { ...state, repCustomers: customers };
  }

  if (action.type === DONE_LOADING) {
    return { ...state, isLoading: false };
  }
  if (action.type === ADD_CLIENT) {
    const { name, value } = action.payload;

    return { ...state, newCustomer: { ...state.newCustomer, [name]: value } };
  }

  if (action.type === CLEAR_FORM) {
    return {
      ...state,
      newCustomer: {
        ...state.newCustomer,
        contact: '',
        company: '',
        name: '',
        phone: '',
        position: '',
        address: '',
        email: '',
      },
    };
  }
  // if (action.type === REP_FORM) {
  //   return {
  //     ...state,
  //     newCustomer: {
  //       ...state.newCustomer,
  //       userRef: action.payload.uid,
  //       month: months[getMonth.getMonth()],
  //       type: 'customer',

  //     },
  //   };
  // }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default clientsReducer;
