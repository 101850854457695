import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DataGrid } from '@mui/x-data-grid';

import { Link, useParams } from 'react-router-dom';
import { useLeadContext } from '../context/leadContext';
import { useFormContext } from '../context/context';
import { useFilterContext } from '../context/filterContext';
import { Spinner } from '../components';
import { ColumnTarget } from '../components';

const SalesRep = () => {
  const [filtered_leads, setFiltered_leads] = useState([]);

  const params = useParams();
  const { leads, isLoading, fetchData } = useLeadContext();
  const { handleNewForm, columns } = useFormContext();

  const {
    updateFilters,
    getUniqueValue,
    filter: { status },
    filtered_status,
  } = useFilterContext();

  const uniqueStatus = getUniqueValue(leads, 'status');

  // performance filter

  let monthlyPerformance = leads.reduce((total, item) => {
    const { month, status } = item;

    if (status === 'converted') {
      if (!total[month]) {
        total[month] = { label: month, value: 1 };
      } else {
        total[month] = { ...total[month], value: total[month].value + 1 };
      }
    }

    return total;
  }, []);

  monthlyPerformance = Object.values(monthlyPerformance).reverse();

  const label = monthlyPerformance.map((item) => {
    const { label } = item;
    return { label };
  });
  const value = monthlyPerformance.map((item) => {
    const { value } = item;
    return { value };
  });

  useEffect(() => {
    fetchData(params.name);
  }, [params.name]);

  useEffect(() => {
    let filteredLeads;

    filteredLeads = leads;

    if (status !== 'all') {
      filteredLeads = leads.filter((lead) => lead.status === status);
    }

    setFiltered_leads(filteredLeads);
  }, [status, isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  if (leads.length <= 0) {
    return (
      <div className='section sharedPage'>
        <h4>No Leads in Your Cabal</h4>
        <Link to={'/input'}>
          <button className='btn' onClick={handleNewForm}>
            Add Lead
          </button>
        </Link>
      </div>
    );
  }

  return (
    <Wrapper className='section sharedPage'>
      <div className='btn-container'>
        <div className='btns'>
          <Link to={'/input'}>
            <button type='btn' className='btn' onClick={handleNewForm}>
              Add Lead
            </button>
          </Link>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <select
              name='status'
              id='status'
              value={status}
              onChange={updateFilters}
              className='btn'
            >
              {uniqueStatus.map((stat, i) => {
                return (
                  <option value={stat} key={i}>
                    {stat.toUpperCase()}
                  </option>
                );
              })}
            </select>
          </form>
        </div>
      </div>

      <DataGrid
        // key={index}
        rows={filtered_leads}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        disableSelectionOnClick
      />

      {/* <hr />
      {value.length <= 0 ? (
        <div>
          <h3>Monthly Target Analysis</h3>
          <h4>0% Target Met</h4>
        </div>
      ) : (
        <ColumnTarget label={label} value={value} />
      )} */}

      {/* {leads.map((lead, index) => {
        let newArray = [];
        newArray.push(lead.data);
        console.log(newArray);
      })} */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  overflow: scroll;
  height: 100vh;
  // max-height: 90vh;
  a {
    color: black;
  }
  .btn {
    margin: 0 0 2rem 1rem;
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export default SalesRep;
