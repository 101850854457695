import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { LineStyle, TrendingUp, Timeline } from '@material-ui/icons';
import { FiUser } from 'react-icons/fi';

import { FaUserTie, FaUsers } from 'react-icons/fa';
import { repData } from '../constant/repsData';
import { useLeadContext } from '../context/leadContext';
import { useFormContext } from '../context/context';
const AdminDash = () => {
  const [activeBtn, setActiveBtn] = useState(null);

  const auth = getAuth();
  const { fetchALLData } = useLeadContext();
  const { closeSideBar } = useFormContext();
  return (
    <ul className='sidebar-list'>
      <NavLink
        to={'/'}
        onClick={() => {
          closeSideBar();
          return fetchALLData();
        }}
        className='item'
      >
        <li>
          <LineStyle className='icon' /> Home
        </li>
      </NavLink>
      {/* <Link
        to={'/'}
        onClick={() => {
          return fetchALLData();
        }}
      >
        <li
          className={`${activeBtn === 'Home' ? 'item active' : 'item'}`}
          onClick={() => {
            setActiveBtn('Home');
          }}
        >
          <LineStyle className='icon' /> Home
        </li>
      </Link> */}
      <NavLink to={'/allleads'} className='item' onClick={closeSideBar}>
        <li>
          <TrendingUp className='icon' />
          All Leads
        </li>
      </NavLink>
      <NavLink to={'/profile'} className='item' onClick={closeSideBar}>
        <li>
          <FiUser className='icon' />
          Profile
        </li>
      </NavLink>
      <NavLink
        to={`/leads/sales/${auth.currentUser.uid}`}
        className='item'
        onClick={closeSideBar}
      >
        <li>
          <Timeline className='icon' />
          My Leads
        </li>
      </NavLink>

      <hr />

      <h4 className='title'>Clients</h4>
      <NavLink to={'/customers'} className='item' onClick={closeSideBar}>
        <li>
          <FaUsers />
          Customers
        </li>
      </NavLink>

      <hr />
      <h4 className='title'>Sales Reps</h4>

      {repData.map((rep, index) => {
        const { name, id } = rep;
        return (
          <NavLink
            to={`/leads/sales/${id}`}
            key={index}
            className='item'
            onClick={closeSideBar}
          >
            <li>
              <FaUserTie className='icon' />
              {name}
            </li>
          </NavLink>
        );
      })}
    </ul>
  );
};

export default AdminDash;
