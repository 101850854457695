export const GET_LEADS_BEGIN = 'GET_LEADS_BEGIN';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_CLIENT_BEGIN = 'GET_CLIENT_BEGIN';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const NEW_FORM = 'NEW_FORM';
export const REP_FORM = 'REP_FORM';
export const ON_SUBMIT = 'ON_SUBMIT';
export const IS_LOADING = 'IS_LOADING';
export const DONE_LOADING = 'DONE_LOADING';
export const ADD_CLIENT = 'ADD_CLIENT ';
export const SUBMIT_UPDATE = 'SUBMIT_UPDATE ';
export const FETCH_ALL_DATA = 'FETCH_ALL_DATA';
export const LOG_UPDATE = 'LOG_UPDATE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const FILTER_LEADS = ' FILTER_PRODUCTS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const LOAD_LEADS = 'LOAD_LEADS';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const NEW_ORDERS = 'NEW_ORDERS';
export const GET_ORDERS_BEGIN = 'GET_ORDERS_BEGIN';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const CLEAR_FORM = 'CLEAR_FORM';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const REP_CUSTOMER = 'REP_CUSTOMER';
