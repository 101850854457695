import React, { useState } from 'react';
import styled from 'styled-components';
import { useClientContext } from '../context/clientsContext';

import { FormRow } from '../components';
import { db } from '../firebase.config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams } from 'react-router';
import { useFormContext } from '../context/context';
const OrderInput = () => {
  const { newCustomer, handleUpdateOrder, handleNewOrderSubmit, newOrder } =
    useClientContext();
  const { isDisable, setIsDisable } = useFormContext();
  const { id } = useParams();

  // const {
  //   name,
  //   company,
  //   phone,
  //   position,
  //   address,
  //   id,
  //   contact,
  //   email,
  //   orders,
  //   orderDate,
  // } = newCustomer;

  const { date, item, quantity, unit_price } = newOrder;
  return (
    <Wrapper>
      <form
        className='form'
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormRow
          type='date'
          name='date'
          value={date}
          handleChange={handleUpdateOrder}
        />
        <FormRow
          type='text'
          name='item'
          value={item}
          handleChange={handleUpdateOrder}
        />
        <FormRow
          type='number'
          name='quantity'
          value={quantity}
          handleChange={handleUpdateOrder}
        />
        <FormRow
          type='number'
          name='unit_price'
          value={unit_price}
          handleChange={handleUpdateOrder}
        />

        <button
          type='submit'
          disabled={isDisable}
          className='btn'
          onClick={() => {
            setIsDisable(true);
            handleNewOrderSubmit(id);
          }}
        >
          Submit
        </button>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.section``;
export default OrderInput;
