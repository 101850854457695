import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';

// Resolves charts dependancy
charts(FusionCharts);

const ColumnTarget = ({ label, value }) => {
  const targetValue = value.map((item) => {
    let { value } = item;

    return { value: value * 10 };
  });
  const data = {
    categories: [
      {
        category: [
          // {
          //   label: 'Jan',
          // },
          // {
          //   label: 'Feb',
          // },
          // {
          //   label: 'March',
          // },
          // {
          //   label: 'April ',
          // },
          // {
          //   label: 'May',
          // },
          // {
          //   label: 'June',
          // },
          // {
          //   label: 'July',
          // },
          // {
          //   label: 'Aug',
          // },
          // {
          //   label: 'Sep',
          // },
          // {
          //   label: 'Oct',
          // },
          // {
          //   label: 'Nov',
          // },
          // {
          //   label: 'Dec',
          // },
          ...label,
        ],
      },
    ],
    dataset: [
      {
        seriesname: 'Clients Onboarded',
        plottooltext: ' $dataValue new clients',
        data: [
          // {
          //   value: '',
          // },
          // {
          //   value: '',
          // },
          // {
          //   value: '',
          // },
          // {
          //   value: '',
          // },
          ...value,
        ],
      },

      {
        seriesname: 'Target met %',
        parentyaxis: 'S',
        renderas: 'line',
        plottooltext: '$dataValue target met',
        showvalues: '0',
        data: [
          // {
          //   value: '',
          // },
          // {
          //   value: '',
          // },
          // {
          //   value: '',
          // },
          // {
          //   value: '',
          // },
          ...targetValue,
        ],
      },
    ],
  };

  const dataSource = {
    chart: {
      caption: 'Monthly Target Analysis',
      // subcaption: 'By Month',
      yaxisname: 'Number of New Clients',
      syaxisname: 'Target %',
      labeldisplay: 'rotate',
      snumbersuffix: '%',
      scrollheight: '10',
      // numvisibleplot: '5',
      drawcrossline: '1',
      theme: 'fusion',
    },
    ...data,
  };

  return (
    <ReactFusioncharts
      type='scrollcombidy2d'
      width='100%'
      height='100%'
      dataFormat='JSON'
      dataSource={dataSource}
    />
  );
};

export default ColumnTarget;
