import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getAuth, updateProfile } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';

import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [logData, setLogdata] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
  });

  const [changeDetails, setchangedDetails] = useState(false);
  const { name, email } = logData;

  const onLogOut = () => {
    auth.signOut();
    navigate('/sign-in');
  };

  const onSubmit = async () => {
    try {
      if (auth.currentUser.displayName !== name) {
        // Update display name in firebase

        await updateProfile(auth.currentUser, {
          displayName: name,
        });

        // update in firestore

        const userRef = doc(db, 'users', auth.currentUser.uid);

        await updateDoc(userRef, { name });
      }
    } catch (error) {
      toast.error('Could not Update Profile details');
    }
  };

  const onChanged = (e) => {
    setLogdata((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  return (
    <Wrapper className='sharedPage section'>
      <div>
        <header className='profileHeader'>
          <p className='pageHeader'>My Profile</p>
          <button className='btn' onClick={onLogOut}>
            logOut
          </button>
        </header>

        <main>
          <div className='profileDetailsHeader'>
            <p className='profileDetailsText'>Personal Details</p>

            {/* <p
              className='changePersonalDetails'
              onClick={() => {
                changeDetails && onSubmit();

                setchangedDetails((prevState) => !prevState);
              }}
            >
              {changeDetails ? 'Done' : 'Change'}
            </p> */}
          </div>

          <div className='profileCard'>
            <form>
              <input
                type='text'
                id='name'
                className={!changeDetails ? 'profileName' : 'profileNameActive'}
                disabled={!changeDetails}
                value={name}
                onChange={onChanged}
              />
              <input
                type='text'
                id='email'
                className={
                  !changeDetails ? 'profileEmail' : 'profileEmailActive'
                }
                disabled={!changeDetails}
                value={email}
                onChange={onChanged}
              />
            </form>
          </div>
        </main>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .profileCard {
    margin-top: 1.5rem;
  }
  p {
    margin-bottom: 0;
  }
`;
export default Profile;
