export const repData = [
  {
    name: 'Bikash Mohanty',
    id: '4k4wJq1HTPUStdnWG0inzcXqq5V2',
  },
  {
    name: 'Kenneth Njoku',
    id: 'Z63Y76myEuPm41HzSrhjSCJRu7B3',
  },
  // {
  //   name: 'Mohaz',
  //   id: 'mBqB8mJVDoUJLNOEFEB3Y2cvJQI2',
  // },
];
