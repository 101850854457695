import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { Spinner } from '../components';
import { useLeadContext } from '../context/leadContext';
import { useFormContext } from '../context/context';
import { useFilterContext } from '../context/filterContext';
import { DataGrid } from '@mui/x-data-grid';

const Leads = () => {
  const { columns, handleNewForm } = useFormContext();
  const { allLeads, isLoading, fetchALLData } = useLeadContext();

  const {
    updateFilters,
    getUniqueValue,
    filter: { status, text },
    filtered_status,
  } = useFilterContext();

  const uniqueStatus = getUniqueValue(allLeads, 'status');

  useEffect(() => {
    fetchALLData();
  }, []);

  // useEffect(() => {
  //   setFilteredLeads([...allLeads]);
  //   let temp_leads;
  //   temp_leads = [...allLeads];

  //   if (status !== 'all') {
  //     temp_leads = allLeads.filter((lead) => lead.status === status);
  //   }

  //   setFilteredLeads(temp_leads);
  // }, [status, isLoading]);

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <Wrapper className='section sharedPage'>
      <div className='btns'>
        <Link to={'/input'}>
          <button className='btn' onClick={handleNewForm}>
            Add Lead
          </button>
        </Link>
        <div className='filters'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              type='text'
              name='text'
              value={text}
              onChange={updateFilters}
              className='textInput'
              placeholder='Search...'
            />
            <select
              name='status'
              id='status'
              value={status}
              onChange={updateFilters}
            >
              {uniqueStatus.map((stat, i) => {
                return (
                  <option value={stat} key={i}>
                    {stat.toUpperCase()}
                  </option>
                );
              })}
            </select>
          </form>
        </div>
      </div>
      {filtered_status.length <= 0 && (
        <section className='section sharedPage'>
          <h3>No lead matched your search!!!</h3>
        </section>
      )}
      <DataGrid
        rows={filtered_status}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        // checkboxSelection
        // autoHeight={true}
        disableSelectionOnClick
      />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  // overflow: scroll;
  // width: 85%;
  height: 90vh;
  a {
    color: black;
  }

  .btns {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }
  .textInput,
  select {
    padding: 0.375rem 0.75rem;
    display: inline-block;
    margin-bottom: 5px;
    border-radius: var(--radius);
    background: var(--clr-grey-10);
  }

  .filters {
    min-height: 2rem;
    width: 100%;
    display: grid;
    gap: 1.5rem;
  }
  @media (min-width: 750px) {
    .btns {
      display: grid;
      gap: 2rem;
      grid-template-columns: auto 1fr;
    }
    .filters {
      width: 100%;
      display: flex;
      gap: 1.5rem;
      justify-content: space-between;
    }
    select {
      margin: 0 0 2rem 1rem;
    }
  }
`;

export default Leads;
