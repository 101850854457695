import React, { useEffect } from 'react';
import { Infos, Column3D, ChartData, ColumnTarget } from '../components';
import styled from 'styled-components';

import { useLeadContext } from '../context/leadContext';
const Dashboard = () => {
  const { fetchALLData } = useLeadContext();

  useEffect(() => {
    fetchALLData();
  }, []);
  return (
    <Wrapper className='sharedPage'>
      <Infos />
      <ChartData />
      {/* <ColumnTarget /> */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 100vh;
  overflow: scroll !important;
`;
export default Dashboard;
