import React from 'react';

import ReactFC from 'react-fusioncharts';

import FusionCharts from 'fusioncharts';

import Pie3D from 'fusioncharts/fusioncharts.charts';

import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.umber';

ReactFC.fcRoot(FusionCharts, Pie3D, FusionTheme);

const PieChart = ({ data }) => {
  const chartConfigs = {
    type: 'Pie2D',
    width: '100%',
    height: '400',
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: 'Status Of All Leads',

        theme: 'umber',
        decimals: 0,
        pieRadius: '50%',
        labelFontSize: '15px',
        smartLineThickness: '2',

        // useDataPlotColorForLabels: '1',
        valuePosition: 'inside',
        paletteColors: '#ff0000,#ffd700,#ffff00,#ff9000,#00ff00',
      },

      data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};
export default PieChart;
