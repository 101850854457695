import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import reducer from '../reducer/formReducer';

import {
  NEW_FORM,
  REP_FORM,
  ON_SUBMIT,
  IS_LOADING,
  DONE_LOADING,
  GET_CLIENT_SUCCESS,
  DELETE_CLIENT,
  EDIT_CLIENT,
  ADD_CLIENT,
  LOG_UPDATE,
} from '../actions';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase.config';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutline } from '@material-ui/icons';

import { toast } from 'react-toastify';

const FormContext = React.createContext();

const initialState = {
  formData: {
    id: `Client-${uuidv4().substring(0, 8)}`,
    title: '',
    name: '',
    phone: '',
    email: '',
    company: '',
    status: 'not contacted',
    progress: 0,
    category: 'High Networth',
    priority: '',
    // owner: '',
    description: '',
    position: '',
    address: '',
    updates: [],
    follow_up: '',
    timestamp: new Date().toISOString(),
  },
  isLoading: false,
  leads: [],
  editMode: false,
  isSideBar: false,
};

const FormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isDisable, setIsDisable] = useState(false);

  // Set userRef to loggedin user
  const checkMounted = () => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          dispatch({ type: REP_FORM, payload: user });
        } else {
          navigate('/sign-in');
        }
      });
    }
    return () => {
      isMounted.current = false;
    };
  };

  const auth = getAuth();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  const columns = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'title', headerName: 'Title', width: 50 },
    { field: 'position', headerName: 'Position', width: 80 },
    {
      field: 'name',
      headerName: 'Name',
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/leads/' + params.row.id} style={{ color: '#1B98E0' }}>
              {params.row.name}
            </Link>
          </>
        );
      },
    },

    {
      field: 'phone',
      headerName: 'Phone',
      type: 'number',
      width: 90,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 100,
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 90,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => {
        let color;
        switch (params.row.status) {
          case 'not contacted':
            color = 'yellow';
            break;

          case 'contacted':
            color = '#9EB25D';
            break;

          case 'pitching':
            color = '#FF9000';
            break;
          case 'converted':
            color = 'green';
            break;
          case 'not converted':
            color = 'red';
            break;
          default:
            color = 'rgb(186,225,255)';
            break;
        }

        return (
          <>
            <div className='status-display' style={{ background: `${color}` }}>
              {params.row.status}
            </div>
          </>
        );
      },
    },
    {
      field: 'progress',
      headerName: 'Progress',
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <div className='progress-display'>
              <div className='progress-container'>
                <div
                  className='progress-indicator'
                  style={{
                    width: `${params.row.progress}%`,
                    textAlign: 'center',
                  }}
                >
                  {params.row.progress}%
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 90,
    },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 90,
    },

    {
      field: 'owner',
      headerName: 'Owner',
      width: 90,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={'/input/' + params.row.id}
              onClick={() => {
                handleEdit(params.row.id);
              }}
            >
              <button
                className='userListEdit'
                onClick={() => setIsDisable(false)}
              >
                Edit
              </button>
            </Link>
            {auth.currentUser.uid === 'XTYZO9A108R93YhuErkjGJvfv4i2' && (
              <DeleteOutline
                className='userListDelete'
                onClick={() => handleDelete(params.row.id)}
              />
            )}
          </>
        );
      },
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    dispatch({ type: NEW_FORM, payload: { value, name } });
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    dispatch({ type: IS_LOADING });

    dispatch({ type: ON_SUBMIT });
    const { formData } = state;

    const docRef = await addDoc(collection(db, 'leads'), formData);
    // dispatch({ type: LOG_UPDATE });
    // setIsDisable(true);
    dispatch({ type: GET_CLIENT_SUCCESS });
    toast.success('Client Added Successfuly');
    navigate(`/leads/${docRef.id}`);
  };

  const handleEdit = async (id) => {
    dispatch({ type: IS_LOADING });
    const docRef = doc(db, 'leads', id);
    const docSnap = await getDoc(docRef);

    dispatch({ type: EDIT_CLIENT, payload: { id, docSnap } });
    dispatch({ type: DONE_LOADING });
  };

  const handleUpdateForm = async (id) => {
    dispatch({ type: IS_LOADING });
    try {
      const docRef = doc(db, 'leads', id);
      await updateDoc(docRef, state.formData);
      // setIsDisable(true);

      toast.success('Info Updated Successfuly');
      dispatch({ type: DONE_LOADING });
      navigate(`/leads/${id}`);
    } catch (error) {
      toast.error('You dont have the permission to edit this account');
      navigate(`/leads/sales/${auth.currentUser.uid}`);
    }
  };

  const handleNewForm = () => {
    dispatch({ type: ADD_CLIENT });
    setIsDisable(false);
  };

  const handleDelete = async (id) => {
    dispatch({ type: IS_LOADING });
    try {
      if (window.confirm('Record will be permanently deleted')) {
        await deleteDoc(doc(db, 'leads', id));
        dispatch({ type: DELETE_CLIENT, payload: id });
        dispatch({ type: DONE_LOADING });
        toast.success('Record Deleted successfuly');
        navigate('/profile');
        return;
      }
      navigate(`/leads/sales/${auth.currentUser.uid}`);
    } catch (error) {
      toast.error('You dont have the permission to delete this account ');
      navigate(`/leads/sales/${auth.currentUser.uid}`);
    }
  };

  // toggle sidebar
  const openSideBar = () => {
    dispatch({ type: 'OPEN_SIDEBAR' });
  };
  const closeSideBar = () => {
    dispatch({ type: 'CLOSE_SIDEBAR' });
  };

  useEffect(() => {
    checkMounted();
  }, [isMounted]);

  return (
    <FormContext.Provider
      value={{
        ...state,
        handleChange,
        handleDelete,
        checkMounted,
        handleSubmit,
        handleEdit,
        handleNewForm,
        handleUpdateForm,
        columns,
        openSideBar,
        closeSideBar,
        isDisable,
        setIsDisable,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
export const useFormContext = () => {
  return useContext(FormContext);
};

export { FormContext, FormProvider };
