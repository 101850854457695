import React from 'react';
import styled from 'styled-components';
import Dashboard from './Dashboard';
import { SideBar } from '../components';
const Home = () => {
  return (
    <Wrapper className='sharedPage'>
      <SideBar />
      <Dashboard />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100vw;
`;
export default Home;
