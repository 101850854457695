import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import loginSvg from '../assets/images/login1.png';
import bcg from '../assets/svg/forgot.svg';
import { toast } from 'react-toastify';
import { ReactComponent as ArrowRightIcon } from '../assets/svg/keyboardArrowRightIcon.svg';
import styled from 'styled-components';
const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const auth = getAuth();
  const onChange = (e) => setEmail(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Email sent Successfuly');
      navigate('/sign-in');
    } catch (error) {
      toast.error('Could not reset Password');
    }
  };

  return (
    <Wrapper className='section pageContainer'>
      <section>
        <header>
          <h4 className='pageHeader'>Forgot Password</h4>
        </header>
        <main>
          <form onSubmit={onSubmit}>
            <input
              type='email'
              placeholder='Email'
              className='emailInput'
              id='email'
              value={email}
              onChange={onChange}
            />

            <Link to='/sign-in' className='forgotPasswordLink'>
              Sign In Instead
            </Link>
            <div className='signInBar'>
              <div className='signInText'>Send Reset link</div>

              <button className='signInButton'>
                <ArrowRightIcon fill='white' width='34px' height='34px' />
              </button>
            </div>
          </form>
        </main>
      </section>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  width: 100vw;
  // max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  display: grid;
  place-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${bcg}) no-repeat center/contain;
  h4 {
    margin-bottom: 4rem;
    color: var(--clr-primary-3);
  }
  p {
    margin-bottom: 0;
    color: black;
  }
  .signInText {
    color: white;
  }
`;
export default ForgotPassword;
