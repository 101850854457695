import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useClientContext } from '../context/clientsContext';
import { useFormContext } from '../context/context';
import { Spinner } from '../components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
const RepCustomers = () => {
  const {
    all_orders,
    columns,
    isLoading,
    fetchCustomers,
    clearForm,
    fetchRepCustomers,
    repCustomers,
  } = useClientContext();
  const { isDisable, setIsDisable } = useFormContext();
  const params = useParams();
  useEffect(() => {
    fetchRepCustomers(params.id);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper className='sharedPage'>
      <Link
        to='/newCustomer'
        onClick={() => {
          setIsDisable(false);
          clearForm();
        }}
        className='btn'
      >
        Add Client
      </Link>
      <DataGrid
        rows={repCustomers}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        // checkboxSelection
        // autoHeight={true}
        disableSelectionOnClick
      />
    </Wrapper>
  );
};
const Wrapper = styled.section`
  overflow: scroll;

  height: 90vh;

  .btn {
    margin: 2rem 0 2rem 0;
    color: white;
  }
`;

export default RepCustomers;
