import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {
  Client,
  Dashboard,
  DataInput,
  Leads,
  SignIn,
  SignUp,
  ForgotPassword,
  Profile,
  Home,
  SalesRep,
  UserHome,
  Landing,
  FormInput,
  SharedPage,
  Customer,
  Customers,
  Error,
  NewCustomerInput,
  OrderInput,
  RepCustomers,
} from './pages';
import { PrivateRoute, ProtectedRoute } from './components';
function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<SharedPage />}>
          <Route element={<ProtectedRoute />}>
            <Route index path='/' element={<Dashboard />} />
            <Route exact path='allleads' element={<Leads />} />
            <Route path='customers' element={<Customers />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/profile' element={<Profile />} />
          </Route>
          <Route exact path='/leads/sales/:name' element={<SalesRep />} />
          <Route exact path='input' element={<DataInput />} />
          <Route exact path='input/:id' element={<DataInput />} />
          <Route exact path='leads/:id' element={<Client />} />
          <Route exact path='newCustomer' element={<NewCustomerInput />} />
          <Route path='repCustomers/:id' element={<RepCustomers />} />
          <Route exact path='newOrder/:id' element={<OrderInput />} />
          <Route path='customer/:id' element={<Customer />} />
          <Route exact path='landing' element={<Landing />} />

          <Route index path='user-home' element={<UserHome />} />
        </Route>
        <Route exact path='sign-up' element={<SignUp />} />
        <Route exact path='sign-in' element={<SignIn />} />
        <Route exact path='forgot-password' element={<ForgotPassword />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
