import React from 'react';
import { Outlet } from 'react-router';
import { NavBar, SideBar, SidebarWrapper } from '../components';
import styled from 'styled-components';
const SharedPage = () => {
  return (
    <Wrapper>
      <div>
        <SideBar />
      </div>
      <div>
        <NavBar />
        <SidebarWrapper />
        <Outlet />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  display: grid;
  grid-template-columns: auto 1fr;

  max-width: 100vw;

  @media (min-width: 900px) {
    grid-template-columns: auto 1fr;
  }
`;
export default SharedPage;
