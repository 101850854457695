import React from 'react';
import {
  LOAD_LEADS,
  UPDATE_FILTERS,
  FILTER_LEADS,
  CLEAR_FILTERS,
} from '../actions';

const filterReducer = (state, action) => {
  if (action.type === UPDATE_FILTERS) {
    let { name, value } = action.payload;

    return { ...state, filter: { ...state.filter, [name]: value } };
  }

  if (action.type === LOAD_LEADS) {
    return {
      ...state,
      all_status: action.payload,
      filtered_status: action.payload,
    };
  }

  if (action.type === FILTER_LEADS) {
    let { all_status } = state;
    let { status, text } = state.filter;
    let temp_status = [...all_status];

    if (text) {
      temp_status = all_status.filter((lead) => {
        return lead.name.toLowerCase().includes(text.toLowerCase());
      });
    }
    if (status !== 'all') {
      temp_status = all_status.filter((lead) => lead.status === status);
    }

    return { ...state, filtered_status: temp_status };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default filterReducer;
