import React from 'react';
import styled from 'styled-components';
import { useClientContext } from '../context/clientsContext';
import { useFormContext } from '../context/context';
import { FormRow } from '../components';
const NewCustomerInput = () => {
  const { handleSubmit, handleChange, newCustomer, isSubmitted } =
    useClientContext();
  const { isDisable, setIsDisable } = useFormContext();
  const { name, company, phone, position, address, id, contact, email } =
    newCustomer;

  const submited = false;

  return (
    <Wrapper>
      <form
        className='form'
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormRow
          type='text'
          name='company'
          value={company}
          handleChange={handleChange}
        />
        <FormRow
          type='text'
          name='name'
          value={name}
          handleChange={handleChange}
        />
        <FormRow
          type='email'
          name='email'
          value={email}
          handleChange={handleChange}
        />
        <FormRow
          type='text'
          name='position'
          value={position}
          handleChange={handleChange}
        />
        <FormRow
          type='number'
          name='phone'
          value={phone}
          handleChange={handleChange}
        />
        <FormRow
          type='text'
          name='contact'
          value={contact}
          handleChange={handleChange}
        />
        <FormRow
          type='text'
          name='address'
          value={address}
          handleChange={handleChange}
        />

        <button
          type='submit'
          disabled={isDisable}
          className='btn'
          onClick={() => {
            setIsDisable(true);
            handleSubmit();
          }}
        >
          Submit
        </button>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.section``;
export default NewCustomerInput;
