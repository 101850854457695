import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { toast } from 'react-toastify';
import loginSvg from '../assets/images/login1.png';
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';

import { setDoc, doc, serverTimestamp } from 'firebase/firestore';

import { db } from '../firebase.config';

import visibilityIcon from '../assets/svg/visibilityIcon.svg';

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [logData, setLogData] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    staffId: '',
  });

  const { name, email, password, role, staffId } = logData;

  const handleChange = (e) => {
    setLogData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      updateProfile(auth.currentUser, {
        displayName: name,
      });

      const formDataCopy = { ...logData };
      // delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();

      await setDoc(doc(db, 'users', user.uid), formDataCopy);

      navigate('/');
    } catch (error) {
      toast.error('Registration was unsuccesful');
    }
  };

  return (
    <Wrapper>
      <div className='pageContainer'>
        <header>
          <h4 className='pageHeader'>Create Account</h4>
        </header>

        <form onSubmit={onSubmit}>
          <input
            type='text'
            id='name'
            value={name}
            onChange={handleChange}
            className='nameInput'
            placeholder='Name'
          />
          <input
            type='email'
            id='email'
            value={email}
            onChange={handleChange}
            className='emailInput'
            placeholder='Email'
          />
          <input
            type='text'
            id='role'
            value={role}
            onChange={handleChange}
            className='nameInput'
            placeholder='Role'
          />
          <input
            type='number'
            id='staffId'
            value={staffId}
            onChange={handleChange}
            className='nameInput'
            placeholder='Staff Id'
          />

          <div className='passwordInputDiv'>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              placeholder='Password'
              className='passwordInput'
              value={password}
              onChange={handleChange}
            />
            {/* <img
              src={visibilityIcon}
              alt='show password'
              className='showPassword'
            /> */}
          </div>

          <div className='signUpBar'>
            <p className='signUpText'>Sign Up</p>
            <button className='signUpButton' type='submit'>
              <AiOutlineArrowRight fill='#ffffff' width='36px' height='36px' />
            </button>
          </div>
        </form>

        <Link to={'/sign-in'} className='registerLink'>
          Sign In Instead
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100vw;
  // max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  display: grid;
  place-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${loginSvg}) no-repeat center/cover;
  h4 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 0;
    color: black;
  }
  form {
    max-width: var(--fixed-width);
  }
`;

export default SignUp;
