import React from 'react';
import useAuthStatus from '../useAuthStatus';
import { getAuth } from 'firebase/auth';
import { Spinner } from '../components';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const { loggedIn, checkingStatus } = useAuthStatus();
  const auth = getAuth();

  if (checkingStatus) {
    return <Spinner />;
  }

  return (loggedIn &&
    auth.currentUser.uid === 'PO292xK8OcYx71LjD7PoU9BnUNt2') ||
    auth.currentUser.uid === 'XTYZO9A108R93YhuErkjGJvfv4i2' ||
    auth.currentUser.uid === '4k4wJq1HTPUStdnWG0inzcXqq5V2' ? (
    <Outlet />
  ) : (
    <Navigate to='/profile' />
  );
};

export default ProtectedRoute;
