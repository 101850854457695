import React, { useContext, useReducer, useEffect } from 'react';
import { Spinner } from '../components';
import { useParams } from 'react-router';
import {
  LOAD_LEADS,
  UPDATE_FILTERS,
  FILTER_LEADS,
  CLEAR_FILTERS,
} from '../actions';
import reducer from '../reducer/filterReducer';
import { useLeadContext } from './leadContext';

const initialState = {
  all_status: [],
  filtered_status: [],
  repFiltered_status: [],
  filter: {
    status: 'all',
    text: '',
  },
};

const FilterContext = React.createContext();

export const FilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { allLeads, fetchALLData, isLoading } = useLeadContext();

  const updateFilters = (e) => {
    let name = e.target.name;

    let value = e.target.value;

    dispatch({ type: UPDATE_FILTERS, payload: { name, value } });
  };

  const getUniqueValue = (data, type) => {
    let uniqueStatus = data.map((item) => item[type]);

    uniqueStatus = ['all', ...new Set(uniqueStatus)];

    return uniqueStatus;
  };

  useEffect(() => {
    fetchALLData();
  }, []);

  useEffect(() => {
    if (allLeads) {
      dispatch({ type: LOAD_LEADS, payload: allLeads });
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch({ type: FILTER_LEADS });
  }, [state.filter.status, state.filter.text]);

  return (
    <FilterContext.Provider value={{ ...state, updateFilters, getUniqueValue }}>
      {children}
    </FilterContext.Provider>
  );
};
export const useFilterContext = () => {
  return useContext(FilterContext);
};
