import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  FETCH_CLIENTS,
  FETCH_CUSTOMERS,
  NEW_ORDERS,
  GET_ORDERS_BEGIN,
  GET_ORDERS_SUCCESS,
  IS_LOADING,
  DONE_LOADING,
  ADD_CLIENT,
  ON_SUBMIT,
  REP_CUSTOMER,
  CLEAR_FORM,
} from '../actions';
import clientsReducer from '../reducer/clientsReducer';
import { Link, useNavigate } from 'react-router-dom';
import { useFormContext } from './context';

import { toast } from 'react-toastify';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  // limit,
  // startAfter,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase.config';
import { getAuth } from 'firebase/auth';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const getMonth = new Date();

const ClientsContext = React.createContext();

const initialState = {
  newCustomer: {
    contact: '',
    company: '',
    name: '',
    phone: '',
    position: '',
    address: '',
    id: '',
    email: '',
    timestamp: '',

    orders: [],
  },
  all_orders: [],
  isLoading: true,
  repCustomers: [],
};
const ClientsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(clientsReducer, initialState);
  const { setIsDisable } = useFormContext();
  const [newOrder, setNewOrder] = useState({
    date: '',
    item: '',
    quantity: 0,
    unit_price: 0,
  });
  const [ordersHolder, setOrdersHolder] = useState({});
  const auth = getAuth();
  const navigate = useNavigate();

  // columns
  const columns = [
    { field: 'id', headerName: 'ID', width: 150 },

    {
      field: 'company',
      headerName: 'Company',
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={'/customer/' + params.row.id}
              style={{ color: '#1B98E0' }}
              // onClick={() => {
              //   handleGetDoc(params.row.id);
              // }}
            >
              {params.row.company}
            </Link>
          </>
        );
      },
    },
    { field: 'contact', headerName: 'Contact Person', width: 160 },
    { field: 'position', headerName: 'Position', width: 100 },
    {
      field: 'phone',
      headerName: 'Phone',
      type: 'number',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
    },

    {
      field: 'owner',
      headerName: 'Owner',
      width: 150,
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Link
    //           to={'/input/' + params.row.id}
    //           onClick={() => {
    //             handleEdit(params.row.id);
    //           }}
    //         >
    //           <button className='userListEdit'>Edit</button>
    //         </Link>
    //         {auth.currentUser.uid === 'XTYZO9A108R93YhuErkjGJvfv4i2' && (
    //           <DeleteOutline
    //             className='userListDelete'
    //             onClick={() => handleDelete(params.row.id)}
    //           />
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  // const handleEdit = (e) => {
  //   console.log(e.target);
  // };
  // const handleDelete = (e) => {
  //   console.log(e.target);
  // };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    dispatch({ type: IS_LOADING });
    const timestamp = serverTimestamp();

    const newData = {
      ...state.newCustomer,
      timestamp: timestamp,
      owner: auth.currentUser.displayName,
      userRef: auth.currentUser.uid,
      month: months[getMonth.getMonth()],
      type: 'customer',
    };
    const docRef = await addDoc(collection(db, 'clients'), newData);

    dispatch({ type: DONE_LOADING });
    toast.success('Client Added Successfuly');
    navigate(`/customer/${docRef.id}`);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    dispatch({ type: ADD_CLIENT, payload: { name, value } });
  };

  // get document from server to edit
  const handleGetDoc = async (id) => {
    dispatch({ type: IS_LOADING });
    const docRef = doc(db, 'clients', id);
    const docSnap = await getDoc(docRef);
    setOrdersHolder({
      ...docSnap.data(),
    });
  };

  const fetchCustomers = async () => {
    dispatch({ type: IS_LOADING });
    try {
      // get reference

      const orderRef = collection(db, 'clients');

      // create query

      const q = query(
        orderRef,
        where('type', '==', 'customer'),

        orderBy('timestamp', 'desc')
      );

      // execute query

      const querySnap = await getDocs(q);
      dispatch({ type: FETCH_CUSTOMERS, payload: querySnap });
      dispatch({ type: DONE_LOADING });
    } catch (error) {
      console.log(error);
    }
  };

  // fetch rep customers

  const fetchRepCustomers = async (id) => {
    dispatch({ type: IS_LOADING });
    try {
      // get refrence

      const orderRef = collection(db, 'clients');

      // create query

      const q = query(
        orderRef,
        where('userRef', '==', `${id}`),
        orderBy('timestamp', 'desc')
      );

      // excute query

      const querySnap = await getDocs(q);
      dispatch({ type: REP_CUSTOMER, payload: querySnap });
      dispatch({ type: DONE_LOADING });
    } catch (error) {
      console.log(error);
    }
  };

  // handling of customer new order

  const handleUpdateOrder = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewOrder({ ...newOrder, [name]: value });
  };

  const handleNewOrderSubmit = async (id) => {
    dispatch({ type: IS_LOADING });

    try {
      ordersHolder.orders.unshift(newOrder);
      const docRef = doc(db, 'clients', id);

      await updateDoc(docRef, ordersHolder);
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: DONE_LOADING });
    navigate(`/customer/${id}`);
    toast.success('Order Added successfully');
    setNewOrder({ date: '', item: '', quantity: 0, unit_price: 0 });
  };

  const clearForm = () => {
    dispatch({ type: CLEAR_FORM });
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <ClientsContext.Provider
      value={{
        ...state,
        columns,
        fetchCustomers,
        handleChange,

        handleSubmit,

        handleGetDoc,
        newOrder,
        handleNewOrderSubmit,
        handleUpdateOrder,
        clearForm,
        fetchRepCustomers,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
export const useClientContext = () => {
  return useContext(ClientsContext);
};
export default ClientsProvider;
