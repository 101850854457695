import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo/Shan-logo-before.png';
// import logo from '../assets/logo/Shan-logo-icon.png';
import { FaBars } from 'react-icons/fa';
import { useFormContext } from '../context/context';
const NavBar = () => {
  const { isSibeBar, openSideBar, closeSideBar } = useFormContext();
  return (
    <Wrapper>
      <div className='nav-center'>
        <div className='container'>
          <div className='img-container'>
            <img src={logo} alt='Shan Packaging' />
          </div>
          <button className='toggleBtn' onClick={openSideBar}>
            <FaBars />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  width: 100vw;
  height: 4rem;
  background: white;
  position: fixed;
  top: 0;
  z-index: 4;

  .nav-center {
    width: 90vw;
    margin: 0 auto;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.3rem;
  }
  .toggleBtn {
    background: transparent;
    font-size: 2rem;
    color: var(--clr-red-dark);
    cursor: pointer;
  }
  .img-container {
    width: 9rem;
    height: 3rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 900px) {
    display: none;
  }
`;
export default NavBar;
