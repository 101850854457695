import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import AdminDash from './AdminDash';
import RepDash from './RepDash';
import { IoMdClose } from 'react-icons/io';
import { useFormContext } from '../context/context';
const SidebarWrapper = () => {
  const { isSideBar, closeSideBar } = useFormContext();
  const auth = getAuth();
  if (auth.currentUser === null) {
    return;
  }
  return (
    <Wrapper>
      <div className={`${isSideBar ? 'dropdown showDropDown' : 'dropdown'}`}>
        <div className='newSide-bar'>
          <h4 className='title'>Dashboard</h4>

          {auth.currentUser.uid === 'XTYZO9A108R93YhuErkjGJvfv4i2' ||
          auth.currentUser.uid === 'PO292xK8OcYx71LjD7PoU9BnUNt2' ||
          auth.currentUser.uid === '4k4wJq1HTPUStdnWG0inzcXqq5V2' ? (
            <AdminDash />
          ) : (
            <RepDash />
          )}
          <button onClick={closeSideBar}>
            <IoMdClose className='closeBtn' />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.aside`
  .dropdown {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    z-index: 999;
    transform: translateX(-100%);
    transition: var(--transition);
  }
  .newSide-bar {
    position: relative;
    background: white;
    height: 100vh;
    width: 100vw;
    padding: 2rem;
  }
  .showDropDown {
    transform: translateX(0);
  }
  .title {
    font-size: 13px;
    color: #bbbaba;
    margin-bottom: 0.1rem;
  }
  .sidebar-list {
    padding: 5px;
  }
  .item {
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 1rem;
    border-radius: 10px;
    color: #555;
    width: 8rem;

    transition: var(--transition);
    svg {
      margin-right: 5px;
      font-size: 1.3rem;
    }
  }
  // .item.active,
  // .item:hover {
  //   background-color: #f0f0ff;
  // }
  .active {
    background-color: #f0f0ff;
    width: 8rem;
  }
  .active,
  .item:hover {
    background-color: #f0f0ff;
    width: 8rem;

    transform: translateX(5px);
  }
  li {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  .closeBtn {
    color: red;
    font-size: 2rem;
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;
export default SidebarWrapper;
