import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import AdminDash from './AdminDash';
import RepDash from './RepDash';

import { useLeadContext } from '../context/leadContext';

import { FiUser } from 'react-icons/fi';

const SideBar = () => {
  const [newUser, setNewUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const { fetchALLData } = useLeadContext();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // console.log(user.uid);
      setLoading(true);
      if (user) {
        setNewUser(user.uid);
        setLoading(false);
      } else {
        // navigate('/sign-in');
        console.log('error loading user');
      }
    });
  }, []);
  // if (auth.currentUser === null) {
  //   return <h3>Loading...</h3>;
  // }

  if (loading) {
    return <h5>Loading...</h5>;
  }
  return (
    <>
      <Wrapper>
        <div className='sidebar-container section  side-container'>
          <h4 className='title'>Dashboard</h4>

          {/* {auth.currentUser.uid === 'XTYZO9A108R93YhuErkjGJvfv4i2' ||
          auth.currentUser.uid === 'PO292xK8OcYx71LjD7PoU9BnUNt2' ? (
            <AdminDash />
          ) : (
            <RepDash />
          )} */}
          {newUser === 'XTYZO9A108R93YhuErkjGJvfv4i2' ||
          newUser === 'PO292xK8OcYx71LjD7PoU9BnUNt2' ||
          newUser === '4k4wJq1HTPUStdnWG0inzcXqq5V2' ? (
            <AdminDash />
          ) : (
            <RepDash />
          )}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 150px;
  height: 100vh;
  display: none;
  .side-container {
    text-align: center;
    flex-direction: column;

    max-width: 150px;
    background: #fbfbff;
    height: 100vh;
    padding-top: 2rem !important;
  }

  .sidebar-container {
    padding: 10px;
  }

  .title {
    font-size: 13px;
    color: #bbbaba;
    margin-bottom: 0.1rem;
  }
  .sidebar-list {
    padding: 5px;
  }
  .item {
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 1rem;
    border-radius: 10px;
    color: #555;

    svg {
      margin-right: 5px;
      font-size: 1.3rem;
    }
  }
  // .item.active,
  // .item:hover {
  //   background-color: #f0f0ff;
  // }
  .active,
  .item:hover {
    background-color: #f0f0ff;
  }
  li {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  /* .sideloading {
    display: hidden;
  } */
  @media (min-width: 992px) {
    display: block;
    .side-container {
      display: flex;
    }
    .newSide-bar {
      display: none;
    }
  }
`;
export default SideBar;
