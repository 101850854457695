import {
  GET_CLIENT_BEGIN,
  GET_CLIENT_SUCCESS,
  GET_LEADS_BEGIN,
  GET_LEADS_SUCCESS,
  EDIT_CLIENT,
  DELETE_CLIENT,
  NEW_FORM,
  REP_FORM,
  ON_SUBMIT,
  SUBMIT_UPDATE,
  IS_LOADING,
  DONE_LOADING,
  ADD_CLIENT,
  LOG_UPDATE,
} from '../actions';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const form_reducer = (state, action) => {
  if (action.type === NEW_FORM) {
    const { name, value } = action.payload;
    return { ...state, formData: { ...state.formData, [name]: value } };
  }

  if (action.type === REP_FORM) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const getMonth = new Date();

    return {
      ...state,
      formData: {
        ...state.formData,
        userRef: action.payload.uid,
        type: 'lead',
        month: months[getMonth.getMonth()],
        owner: action.payload.displayName,
      },
    };
  }

  if (action.type === IS_LOADING) {
    return { ...state, isLoading: true };
  }
  if (action.type === DONE_LOADING) {
    return { ...state, isLoading: false };
  }

  if (action.type === ON_SUBMIT) {
    const formDataCopy = {
      ...state.formData,
      timestamp: serverTimestamp(),
      ...state,
      isLoading: false,
    };

    return { ...formDataCopy };
  }

  // if (action.type === LOG_UPDATE) {
  //   const { description } = state.formData;
  //   const newUpdate = [...state.logUpdates, description];
  //   return { ...state, formData: { ...state.formData, updates: newUpdate } };
  // }

  if (action.type === GET_LEADS_BEGIN) {
    return { ...state, leads: action.payload };
  }

  if (action.type === GET_CLIENT_SUCCESS) {
    return {
      ...state,
      formData: {
        ...state.formData,
        id: `Client-${uuidv4().substring(0, 8)}`,
        title: '',
        name: '',
        phone: '',
        email: '',
        company: '',
        status: 'not contacted',
        progress: 0,
        category: 'High Networth',
        priority: '',
        // owner: '',
        description: '',
        address: '',

        timestamp: new Date().toISOString(),
        logTime: '',
      },
      isLoading: false,
    };
  }

  if (action.type === DELETE_CLIENT) {
    const updatedLeads = state.leads.filter(
      (lead) => lead.id !== action.payload
    );
    return { ...state, leads: updatedLeads, isLoading: false };
  }

  if (action.type === EDIT_CLIENT) {
    const { docSnap } = action.payload;

    const { description } = docSnap.data();

    let newUpdates = [...docSnap.data().updates];
    const newTime = new Date().toISOString();

    newUpdates.unshift({ info: description, time: newTime });
    const newForm = {
      ...docSnap.data(),
      updates: newUpdates,
      description: '',
    };

    return {
      ...state,
      formData: newForm,
      editMode: true,
    };
  }
  if (action.type === ADD_CLIENT) {
    return {
      ...state,
      editMode: false,
      formData: {
        ...state.formData,
        ...state.formData,
        id: `Client-${uuidv4().substring(0, 8)}`,
        title: '',
        name: '',
        phone: '',
        email: '',
        company: '',
        status: 'not contacted',
        progress: 0,
        category: 'High Networth',
        priority: '',
        // owner: '',
        description: '',
        follow_up: '',
        updates: [],
        contact_prefrence: '',
        address: '',
        position: '',
        timestamp: new Date().toISOString(),
      },
    };
  }
  if (action.type === 'OPEN_SIDEBAR') {
    return { ...state, isSideBar: true };
  }
  if (action.type === 'CLOSE_SIDEBAR') {
    return { ...state, isSideBar: false };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default form_reducer;
