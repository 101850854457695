import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase.config';
import { Spinner } from '../components';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const Client = () => {
  const [leads, setLeads] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stat, setStat] = useState(1);
  const navigate = useNavigate();
  const params = useParams();

  const steps = ['Open Not Contacted', 'Contacted', 'Pitching', 'Closed'];

  useEffect(() => {
    const fetchLead = async () => {
      const docRef = doc(db, 'leads', params.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLeads(docSnap.data());
        setLoading(false);
      }
    };

    fetchLead();
  }, [navigate, params.id]);

  useEffect(() => {
    if (!leads) {
      return;
    }
    if (leads.status === 'not contacted') setStat(1);
    if (leads.status === 'contacted') setStat(2);
    if (leads.status === 'pitching') setStat(3);
    if (leads.status === 'converted' || leads.status === 'not converted')
      setStat(4);
  }, [leads]);

  if (loading) {
    return <Spinner />;
  }
  const {
    category,
    company,
    description: desc,
    email,
    follow_up,
    name,
    owner,
    phone,
    priority,
    progress,
    contact_prefrence,
    status,
    timestamp,
    title,
    updates,
    month,
  } = leads;

  return (
    <Wrapper className='section sharedPage'>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={stat} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className='owner-title'>
        <h4 className='title'>Lead Owner</h4>
        <p>{owner}</p>
      </div>
      <div className='info-container'>
        <div>
          <h4 className='title'>Title</h4>
          <p>{title}</p>
        </div>
        <div>
          <h4 className='title'>Name</h4>
          <p>{name}</p>
        </div>
        <div>
          <h4 className='title'>Phone Number</h4>
          <p>{phone}</p>
        </div>
        <div>
          <h4 className='title'>Email</h4>
          <p>{email}</p>
        </div>
        <div>
          <h4 className='title'>Company</h4>
          <p>{company}</p>
        </div>

        <div>
          <h4 className='title'>Category</h4>
          <p>{category}</p>
        </div>

        <div>
          <h4 className='title'>Priority</h4>
          <p>{priority}</p>
        </div>
        <div>
          <h4 className='title'>Progress</h4>
          <p>{progress}%</p>
        </div>
        <div>
          <h4 className='title'>Contact Prefrence</h4>
          <p>{contact_prefrence}</p>
        </div>
        <div>
          <h4 className='title'>Status</h4>
          <p>{status}</p>
        </div>
        <div>
          <h4 className='title'>Follow Up Date</h4>
          <p>{follow_up}</p>
        </div>

        <div>
          <h4 className='title'>Date Added</h4>
          <p>{timestamp}</p>
        </div>
        <div>
          <h4 className='title'>Notes</h4>
          <p>{desc}</p>
        </div>
      </div>
      {updates.length > 0 && (
        <>
          <h4 className='owner-title'>Updates Log</h4>
          <div className='update-container'>
            <article>
              {updates.map((u, i) => {
                const { info, time } = u;
                return (
                  <ul key={i} style={{ marginBottom: '2rem' }}>
                    <li style={{ margin: '0' }}>
                      <span className='listTime'>{time}</span>
                      <span>{info}</span>
                    </li>
                  </ul>
                );
              })}
            </article>
          </div>
        </>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  width: 95vw;
  height: 100vh;
  overflow: scroll;
  .info-container {
    // width: 80%;
    width: 100%;
    // height: 75%;
    // height: 100%;
    padding: 2rem;
    // padding: 2rem;
    display: grid;
    overflow: scroll;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    background: white;
    border-radius: var(--radius);

    margin-bottom: 2rem;
  }
  .update-container {
    background: white;
    background: #bbbaba;
    background: black;
    min-height: 10rem;
    max-height: 30rem;
    overflow: scroll;
    padding: 2rem;

    border-radius: var(--radius);

    width: 90%;
    max-width: var(--fixed-width);
  }
  .owner-title {
    padding: 2rem 0 0 1rem;
  }
  .title {
    margin-bottom: 0;
    font-weight: bold;
  }
  li {
    min-height: 2rem;
    padding: 1rem;
    border-radius: var(--radius);
    // background: #bbbaba;
    background: white;
  }
  .listTime {
    display: block;
    color: blue;
    font-size: 0.7rem;
  }
  @media (min-width: 900px) {
    .info-container {
      grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
      width: 90%;
    }
    width: calc(100vw - 150px);
  }
`;
export default Client;
