import React from 'react';

import ReactFC from 'react-fusioncharts';

import FusionCharts from 'fusioncharts';

import Column3D from 'fusioncharts/fusioncharts.charts';

import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

ReactFC.fcRoot(FusionCharts, Column3D, FusionTheme);

const ColumnChart = ({ data }) => {
  const chartConfigs = {
    type: 'Column3D',
    width: '100%',
    height: '400',
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: 'Account Officers with the highest leads',

        xAxisName: 'Account Officer',
        xAxisNameFontSize: '20px',

        yAxisName: 'Numbers of Leads',
        yAxisNameFontSize: '20px',

        theme: 'fusion',
        paletteColors: '#00ff00,#ff0000,#ffd700,#ff9000,#ffff00,#9eb25d',
      },

      data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};
export default ColumnChart;
