import React from 'react';

import { Link } from 'react-router-dom';
import loginSvg from '../assets/images/login1.png';
import logo from '../assets/logo/Shan-logo-before.png';
import styled from 'styled-components';
import { AiOutlineArrowRight } from 'react-icons/ai';

import bcg from '../assets/svg/miro.svg';

import { useAuthContext } from '../context/authContext';

const SignIn = () => {
  const { handleChange, onSubmit, email, password, showPassword } =
    useAuthContext();

  return (
    <Wrapper>
      <div className='pageContainer'>
        <header>
          <h4 className='pageHeader'>Welcome Back</h4>
        </header>
        <form onSubmit={onSubmit}>
          <img src={logo} alt='Shan Packaging' />
          <h3>Login</h3>
          <div className='emailInputDiv'>
            <input
              type='email'
              id='email'
              value={email}
              onChange={handleChange}
              className='emailInput'
              placeholder='Email'
            />
          </div>
          <div className='passwordInputDiv'>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              placeholder='Password'
              className='passwordInput'
              value={password}
              onChange={handleChange}
            />
          </div>
          {/* <div className='signInBar'>
            <p className='signInText'>Sign In</p>
            <button className='signInButton btn'>
              <AiOutlineArrowRight fill='#ffffff' width='36px' height='36px' />
            </button>
          </div> */}{' '}
          <Link to={'/forgot-password'} className='forgotPasswordLink '>
            Forgot Password ?
          </Link>
          <button className='btn'>Sign in</button>
        </form>

        {/* <Link to={'/sign-up'} className='registerLink'>
          <h5>Create Profile</h5>
        </Link> */}
      </div>
      {/* <img src={bcg} alt='shan crm' className='loginBcg' /> */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100vw;
  height: 100vh;
  // max-width: 600px;
  margin: 0 auto;
  text-align: center;
  display: grid;
  place-items: center;
  background: var(--clr-primary-10);
  h4 {
    margin-bottom: 2rem;
  }
  h5 {
    font-size: 1.5rem;
    color: red;
  }
  p {
    margin-bottom: 0;
    color: black;
  }
  form {
    background: white;

    padding: 1rem;
    height: 50vh;
    margin: 0 auto;
    width: 90%;
    max-width: 500px;
    border-radius: var(--radius);
    border-top: 5px solid var(--clr-primary-5);
    img {
      width: 40%;
      display: block;
      margin: 0 auto;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
    button {
      width: 100%;
    }
  }
  @media (min-width: 900px) {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(${bcg}) no-repeat center/cover;

    h4 {
      color: var(--clr-primary-9);
    }
  } ;
`;

export default SignIn;
