import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { LineStyle, TrendingUp, Timeline } from '@material-ui/icons';
import { FiUser } from 'react-icons/fi';

import { FaUserTie, FaUsers } from 'react-icons/fa';
import { repData } from '../constant/repsData';
import { useLeadContext } from '../context/leadContext';
import { useFormContext } from '../context/context';
import { useClientContext } from '../context/clientsContext';

const RepDash = () => {
  const [activeBtn, setActiveBtn] = useState(null);
  const auth = getAuth();
  const { fetchALLData } = useLeadContext();
  const { closeSideBar } = useFormContext();
  const { fetchRepCustomers } = useClientContext();
  return (
    <ul className='sidebar-list'>
      <Link to={'/user-home'} onClick={closeSideBar}>
        <li
          className={`${activeBtn === 'Home' ? 'item active' : 'item'}`}
          onClick={() => {
            setActiveBtn('Home');
          }}
        >
          <LineStyle />
          Home
        </li>
      </Link>
      <Link to={`/leads/sales/${auth.currentUser.uid}`} onClick={closeSideBar}>
        <li
          className={`${activeBtn === 'Leads' ? 'item active' : 'item'}`}
          onClick={() => {
            setActiveBtn('Leads');
          }}
        >
          <TrendingUp className='icon' />
          Leads
        </li>
      </Link>
      <Link to={'/profile'} onClick={closeSideBar}>
        <li
          className={`${activeBtn === 'Profile' ? 'item active' : 'item'}`}
          onClick={() => {
            setActiveBtn('Profile');
          }}
        >
          <FiUser className='icon' />
          Profile
        </li>
      </Link>

      <hr />

      <h4 className='title'>Clients</h4>
      <NavLink
        to={`/repCustomers/${auth.currentUser.uid}`}
        className='item'
        onClick={() => {
          closeSideBar();
          fetchRepCustomers(`${auth.currentUser.uid}`);
        }}
      >
        <li>
          <FaUsers />
          Customers
        </li>
      </NavLink>
    </ul>
  );
};

export default RepDash;
