// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyANfX90ncbeNsLLwmCm8H33IlmEeXBBLvA',
  authDomain: 'shan-crm.firebaseapp.com',
  projectId: 'shan-crm',
  storageBucket: 'shan-crm.appspot.com',
  messagingSenderId: '700249564046',
  appId: '1:700249564046:web:5df5efb204298d05077adf',
  measurementId: 'G-TW79VG0YDS',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();
