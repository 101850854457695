import React from 'react';
import { MdRemoveDone, MdOutlineDoneAll } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { GrInProgress } from 'react-icons/gr';
import { BiConversation } from 'react-icons/bi';
import { useLeadContext } from '../context/leadContext';
import { useFormContext } from '../context/context';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from './Spinner';

const Infos = () => {
  const { handleNewForm } = useFormContext();
  const { allLeads } = useLeadContext();

  const converted = allLeads.filter((c) => c.status === 'converted');

  const notConverted = allLeads.filter((nc) => nc.status === 'not converted');

  const contacted = allLeads.filter((c) => c.status === 'contacted');
  const pitching = allLeads.filter((p) => p.status === 'pitching');

  const notContacted = allLeads.filter((nc) => nc.status === 'not contacted');

  const data = [
    {
      icon: <FiUsers />,
      label: 'Total Leads',
      value: `${allLeads.length}`,
      color: 'gold',
    },
    {
      icon: <MdOutlineDoneAll />,
      label: ' Closed Converted',
      value: `${converted.length}`,
      color: 'green',
    },
    {
      icon: <MdRemoveDone />,
      label: 'Closed Not Converted',
      value: `${notConverted.length}`,
      color: 'red',
    },
    {
      icon: <BiConversation />,
      label: 'Contacted',
      value: `${contacted.length}`,
      color: 'brown',
    },
    {
      icon: <BiConversation />,
      label: 'Pitching',
      value: `${pitching.length}`,
      color: 'orange',
    },
    {
      icon: <GrInProgress />,
      label: 'Not Contacted',
      value: `${notContacted.length}`,
      color: 'yellow',
    },
  ];

  if (allLeads.length <= 0) {
    return <Spinner />;
    // return (
    //   <section className='section sharedPage'>
    //     <h3>No Data to Display</h3>
    //     <Link to={'/input'}>
    //       <button className='btn' onClick={handleNewForm}>
    //         Add Client
    //       </button>
    //     </Link>
    //   </section>
    // );
  }

  return (
    <Wrapper className='section'>
      <div className='container'>
        {data.map((item, index) => {
          const { icon, label, value, color } = item;
          return (
            <article key={index} className='single-card'>
              <span className={color}>{icon}</span>
              <div className='info'>
                <h3>{value}</h3>
                <p>{label}</p>
              </div>
            </article>
          );
        })}
      </div>
    </Wrapper>

    // <DashInfo data={data} />
  );
};

const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;

  .container {
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem 2rem;
    @media (min-width: 640px) {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
  }
  .single-card {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    background: white;
    padding: 2rem 1rem;
    border-radius: 10px;
    box-shadow: var(--dark-shadow);
    column-gap: 3rem;

    span {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
    svg {
      font-size: 1.5rem;
      color: white;
    }

    .gold {
      background: #ffd700;
    }
    .green {
      background: #00ff00;
    }
    .red {
      background: #ff0000;
    }
    .yellow {
      background: #ffff00;
    }
    .brown {
      background: #9eb25d;
    }
    .orange {
      background: #ff9000;
    }
  }
  h3 {
    margin-bottom: 0;
    letter-spacing: 0;
  }
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
`;
export default Infos;
