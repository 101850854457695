import {
  GET_LEADS_BEGIN,
  IS_LOADING,
  DONE_LOADING,
  GET_LEADS_SUCCESS,
  FETCH_ALL_DATA,
} from '../actions';

const leadsReducer = (state, action) => {
  if (action.type === IS_LOADING) {
    return { ...state, isLoading: true };
  }
  if (action.type === GET_LEADS_SUCCESS) {
    const querySnap = action.payload;
    const leads = [];
    querySnap.forEach((doc) => {
      const newData = { ...doc.data(), id: doc.id };

      leads.push(newData);
      // leads.push({ id: doc.id, data: doc.data() });
    });

    return { ...state, leads };
  }

  if (action.type === DONE_LOADING) {
    return { ...state, isLoading: false };
  }

  if (action.type === FETCH_ALL_DATA) {
    return { ...state, allLeads: action.payload };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default leadsReducer;
