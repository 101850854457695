import React from 'react';

import ReactFC from 'react-fusioncharts';

import FusionCharts from 'fusioncharts';

import Bar3D from 'fusioncharts/fusioncharts.charts';

import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

ReactFC.fcRoot(FusionCharts, Bar3D, FusionTheme);

const BarChart = ({ data }) => {
  const chartConfigs = {
    type: 'Bar3D',
    width: '100%',
    height: '400',
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: 'Account Officers with the least converted leads',

        xAxisName: 'Account Officer',
        xAxisNameFontSize: '20px',

        yAxisName: 'Numbers of Leads not Converted',
        yAxisNameFontSize: '20px',

        theme: 'fusion',
        // paletteColors: '#00ff00,#ff0000,#ffd700,#ff9000,#ffff00,#9eb25d',
        paletteColors: '#ff0000',
      },

      data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};
export default BarChart;
