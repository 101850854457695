import React from 'react';
import ReactDOM from 'react-dom/client';
import './indx.css';
import './index.css';
import App from './App';
import { FormProvider } from './context/context';
import { LeadsProvider } from './context/leadContext';
import { FilterProvider } from './context/filterContext';
import { AuthProvider } from './context/authContext';
import { BrowserRouter as Router } from 'react-router-dom';
import ClientsProvider from './context/clientsContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <LeadsProvider>
          <FilterProvider>
            <FormProvider>
              <ClientsProvider>
                <App />
              </ClientsProvider>
            </FormProvider>
          </FilterProvider>
        </LeadsProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
