import React, { useEffect, useReducer, useContext, useState } from 'react';

import reducer from '../reducer/leadReducer';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  // limit,
  // startAfter,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';
import {
  GET_LEADS_SUCCESS,
  IS_LOADING,
  DONE_LOADING,
  FETCH_ALL_DATA,
} from '../actions';

const LeadsContext = React.createContext();
const initialState = {
  leads: [],
  allLeads: [],
  isLoading: false,
};

const LeadsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const auth = getAuth();
  const fetchData = async (uid) => {
    dispatch({ type: IS_LOADING });
    try {
      // get refrence

      const leadsRef = collection(db, 'leads');

      // create a query

      const q = query(
        leadsRef,
        where('userRef', '==', uid),
        orderBy('timestamp', 'desc')
      );

      // execute query

      const querySnap = await getDocs(q);

      dispatch({ type: GET_LEADS_SUCCESS, payload: querySnap });
      dispatch({ type: DONE_LOADING });
    } catch (error) {
      toast.error('Could not fetch leads');
    }
  };

  const fetchALLData = async () => {
    dispatch({ type: IS_LOADING });
    if (auth.currentUser) {
      try {
        // get refrence

        const leadsRef = collection(db, 'leads');

        // create a query

        const q = query(
          leadsRef,
          where('type', '==', 'lead'),
          orderBy('timestamp', 'desc')
        );

        // execute query

        const querySnap = await getDocs(q);

        const leads = [];

        querySnap.forEach((doc) => {
          const newData = { ...doc.data(), id: doc.id };

          leads.push(newData);
          dispatch({ type: FETCH_ALL_DATA, payload: leads });
          //  setLeads(leads);
          dispatch({ type: DONE_LOADING });
        });
      } catch (error) {
        toast.error('Unable to fetch data');
      }
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchALLData();
    }
  }, []);

  return (
    <LeadsContext.Provider value={{ ...state, fetchData, fetchALLData }}>
      {children}
    </LeadsContext.Provider>
  );
};

export const useLeadContext = () => {
  return useContext(LeadsContext);
};
export { LeadsContext, LeadsProvider };
