import React from 'react';
import useAuthStatus from '../useAuthStatus';

import { Spinner } from '../components';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const { loggedIn, checkingStatus } = useAuthStatus();

  if (checkingStatus) {
    return <Spinner />;
  }

  return loggedIn ? <Outlet /> : <Navigate to='/sign-in' />;
};

export default PrivateRoute;
