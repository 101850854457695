import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [logData, setLogData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = logData;

  const handleChange = (e) => {
    setLogData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (
        (userCredential &&
          userCredential.user.uid == 'PO292xK8OcYx71LjD7PoU9BnUNt2') ||
        userCredential.user.uid == 'XTYZO9A108R93YhuErkjGJvfv4i2' ||
        userCredential.user.uid == '4k4wJq1HTPUStdnWG0inzcXqq5V2'
      ) {
        navigate('/');
        setLogData({ email: '', password: '' });
      } else if (userCredential) {
        navigate('/user-home');
        setLogData({ email: '', password: '' });
      }
    } catch (error) {
      toast.error('Wrong User Credentials');
    }
  };
  return (
    <AuthContext.Provider
      value={{
        handleChange,
        onSubmit,
        email,
        password,
        showPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
export { AuthContext, AuthProvider };
