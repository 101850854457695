import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useClientContext } from '../context/clientsContext';
import { useFormContext } from '../context/context';
import { Spinner } from '../components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Customers = () => {
  const { all_orders, columns, isLoading, fetchCustomers, clearForm } =
    useClientContext();
  const { isDisable, setIsDisable } = useFormContext();

  useEffect(() => {
    fetchCustomers();
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper className='sharedPage'>
      <Link
        onClick={() => {
          setIsDisable(false);
          clearForm();
        }}
        to='/newCustomer'
        className='btn'
      >
        Add Client
      </Link>
      <DataGrid
        rows={all_orders}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        // checkboxSelection
        // autoHeight={true}
        disableSelectionOnClick
      />
    </Wrapper>
  );
};
const Wrapper = styled.section`
  overflow: scroll;

  height: 90vh;

  a {
    color: black;
  }
  .btn {
    margin: 2rem 0 2rem 0;
    color: var(--clr-white);
  }

  @media (min-width: 750px) {
    height: 95vh;
  }
`;

export default Customers;
