import React, { useEffect } from 'react';

import { MdRemoveDone, MdOutlineDoneAll } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { GrInProgress } from 'react-icons/gr';
import { BiConversation } from 'react-icons/bi';
import { useLeadContext } from '../context/leadContext';

import { getAuth } from 'firebase/auth';

import { DashInfo, Spinner } from '../components';

const UserHome = () => {
  const { leads, fetchData } = useLeadContext();

  const auth = getAuth();

  const converted = leads.filter((c) => c.status === 'converted');

  const notConverted = leads.filter((nc) => nc.status === 'not converted');

  const contacted = leads.filter((c) => c.status === 'contacted');
  const pitching = leads.filter((p) => p.status === 'pitching');

  const notContacted = leads.filter((nc) => nc.status === 'not contacted');

  const data = [
    {
      icon: <FiUsers />,
      label: 'Total Leads',
      value: `${leads.length}`,
      color: 'gold',
    },
    {
      icon: <MdOutlineDoneAll />,
      label: ' Closed Converted',
      value: `${converted.length}`,
      color: 'green',
    },
    {
      icon: <MdRemoveDone />,
      label: 'Closed Not Converted',
      value: `${notConverted.length}`,
      color: 'red',
    },
    {
      icon: <BiConversation />,
      label: 'Contacted',
      value: `${contacted.length}`,
      color: 'brown',
    },
    {
      icon: <BiConversation />,
      label: 'Pitching',
      value: `${pitching.length}`,
      color: 'orange',
    },
    {
      icon: <GrInProgress />,
      label: 'Not Contacted',
      value: `${notContacted.length}`,
      color: 'yellow',
    },
  ];

  useEffect(() => {
    if (auth.currentUser === null) {
      return;
    }

    fetchData(auth.currentUser.uid);
  }, [auth.currentUser]);

  // if (leads.length <= 0) {
  //   return <Spinner />;
  // }
  return <DashInfo data={data} />;
};

export default UserHome;
