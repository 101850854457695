import React from 'react';
import { BarChart, PieChart, DoughnutChart, ColumnChart } from './charts';
import styled from 'styled-components';
import { useLeadContext } from '../context/leadContext';
const ChartData = () => {
  const { allLeads } = useLeadContext();

  const converted = allLeads.filter((c) => c.status === 'converted');

  const notConverted = allLeads.filter((nc) => nc.status === 'not converted');

  let mostLeads = allLeads.reduce((total, item) => {
    const { owner } = item;
    if (!owner) return total;
    if (!total[owner]) {
      total[owner] = { label: owner, value: 1 };
    } else {
      total[owner] = { ...total[owner], value: total[owner].value + 1 };
    }

    return total;
  }, {});

  mostLeads = Object.values(mostLeads)
    .sort((a, b) => {
      return b.value - a.value;
    })
    .slice(0, 5);

  let leadsStatus = allLeads.reduce((total, item) => {
    const { status } = item;

    if (!total[status]) {
      total[status] = { label: status, value: 1 };
    } else {
      total[status] = { ...total[status], value: total[status].value + 1 };
    }

    return total;
  }, {});

  leadsStatus = Object.values(leadsStatus);

  let mostConverted = converted.reduce((total, item) => {
    const { owner } = item;
    if (!total[owner]) {
      total[owner] = { label: owner, value: 1 };
    } else {
      total[owner] = { ...total[owner], value: total[owner].value + 1 };
    }
    return total;
  }, {});

  mostConverted = Object.values(mostConverted)
    .sort((a, b) => {
      return b.value - a.value;
    })
    .slice(0, 5);

  let leastConverted = notConverted.reduce((total, item) => {
    const { owner } = item;
    if (!total[owner]) {
      total[owner] = { label: owner, value: 1 };
    } else {
      total[owner] = { ...total[owner], value: total[owner].value + 1 };
    }
    return total;
  }, {});

  leastConverted = Object.values(leastConverted)
    .sort((a, b) => {
      return b.value - a.value;
    })
    .slice(0, 5);

  return (
    <Wrapper>
      {leadsStatus.length >= 1 && <PieChart data={leadsStatus} />}

      {mostLeads.length >= 1 && <ColumnChart data={mostLeads} />}
      {mostConverted.length >= 1 && <DoughnutChart data={mostConverted} />}

      {leastConverted.length >= 1 && <BarChart data={leastConverted} />}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  justify-items: center;
  gap: 2rem;
  // max-width: 80%;
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 2fr 3fr;
  }

  div {
    width: 100% !important;
  }
  .fushioncharts-container {
    width: 100% !important;
  }

  svg {
    width: 100% !important;
    border-radius: var(--radius) !important;
  }
`;
export default ChartData;
