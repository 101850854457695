import React from 'react';
import styled from 'styled-components';
const DashInfo = ({ data }) => {
  return (
    <Wrapper className='section sharedPage'>
      <div className='container'>
        {data.map((item, index) => {
          const { icon, label, value, color } = item;
          return (
            <article key={index} className='single-card'>
              <span className={color}>{icon}</span>
              <div className='info'>
                <h3>{value}</h3>
                <p>{label}</p>
              </div>
            </article>
          );
        })}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  // width: 100%;
  // margin: 0 auto;
  overflow: scroll;
  height: 100vh;
  .container {
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem 2rem;
    @media (min-width: 640px) {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
  }
  .single-card {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    background: white;
    padding: 2rem 1rem;
    border-radius: 10px;
    box-shadow: var(--dark-shadow);
    column-gap: 3rem;

    span {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
    svg {
      font-size: 1.5rem;
      color: white;
    }

    .gold {
      background: gold;
    }
    .green {
      background: green;
    }
    .red {
      background: red;
    }
    .yellow {
      background: yellow;
    }
    .brown {
      background: #9eb25d;
    }
    .orange {
      background: #ff9000;
    }
  }
  h3 {
    margin-bottom: 0;
    letter-spacing: 0;
  }
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
`;
export default DashInfo;
