import React, { useState, useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase.config';
import styled from 'styled-components';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Spinner } from '../components';
import { useClientContext } from '../context/clientsContext';
import { useFormContext } from '../context/context';
const Customer = () => {
  const [AllOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleGetDoc } = useClientContext();
  const { setIsDisable } = useFormContext();
  const params = useParams();
  const navigate = useNavigate();

  const TAX_RATE = 0.075;

  const ccyFormat = (num) => {
    return `${num.toFixed(2)}`;
  };

  const formatPrice = (number) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(number);
  };
  const priceRow = (qty, unit) => {
    return qty * unit;
  };

  const createRow = (date, desc, qty, unit) => {
    const price = priceRow(qty, unit);
    return { date, desc, qty, unit, price };
  };

  const subtotal = (items) => {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'clients', params.id);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const newData = { ...docSnap.data(), id: params.id };
        setAllOrders(newData);

        setLoading(false);
      }
    };

    fetchData();
  }, [navigate, params.id]);

  if (loading) {
    return <Spinner />;
  }

  const rows = AllOrders.orders.map((order) => {
    const { date, item, quantity, unit_price } = order;

    return createRow(date, item, quantity, unit_price);
  });

  const invoiceSubtotal = subtotal(rows);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  const {
    company,
    address,
    contact,
    email,
    id,
    month,
    owner,
    phone,
    position,
  } = AllOrders;
  return (
    <Wrapper className='section sharedPage'>
      <TableContainer component={Paper}>
        <div>
          <Link
            to={`/newOrder/${id}`}
            className='btn'
            onClick={() => {
              handleGetDoc(id);
              setIsDisable(false);
            }}
          >
            New Order
          </Link>
          <div className='section header'>
            <h3>{company}</h3>
          </div>
        </div>
        <div className='info'>
          <h5>
            Address : <span>{address}</span>
          </h5>
          <h5>
            Email : <span>{email}</span>
          </h5>
          <h5>
            Contact Person : <span>{contact}</span>
          </h5>
          <h5>
            Position : <span>{position}</span>
          </h5>
          <h5>
            Phone Number : <span>{phone}</span>
          </h5>
          <h5>
            Customer Id : <span>{id}</span>
          </h5>
        </div>
        <h4>Order History</h4>
        <Table sx={{ minWidth: 700 }} aria-label='spanning table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' colSpan={3}>
                <h4>Details</h4>
              </TableCell>
              <TableCell colSpan={1}>
                <h4>Price</h4>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Date</strong>
              </TableCell>
              <TableCell>
                <strong>Items</strong>
              </TableCell>
              <TableCell>
                <strong>Qty.</strong>
              </TableCell>
              <TableCell>
                <strong>Unit Price</strong>
              </TableCell>
              <TableCell>
                <strong>Sum</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ desc, date, qty, unit, price }, index) => (
              <TableRow key={index}>
                <TableCell>{date}</TableCell>
                <TableCell>{desc}</TableCell>
                <TableCell>{qty}</TableCell>
                <TableCell>{formatPrice(unit)}</TableCell>
                <TableCell>{formatPrice(ccyFormat(price))}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell colSpan={3}>
                <strong className='sub'>Subtotal</strong>
              </TableCell>
              <TableCell>
                <strong>{formatPrice(ccyFormat(invoiceSubtotal))}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <strong className='sub'>Tax</strong>
              </TableCell>
              <TableCell>
                <strong>{`${(TAX_RATE * 100).toFixed(2)} %`}</strong>
              </TableCell>
              <TableCell>
                <strong>{formatPrice(ccyFormat(invoiceTaxes))}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>
                <strong className='sub'>Total</strong>
              </TableCell>
              <TableCell className='total'>
                {formatPrice(ccyFormat(invoiceTotal))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  width: 100%;
  max-height: 100vh;
  overflow: scroll;
  /* margin: 0 auto; */

  .header,
  h3 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }

  h4 {
    width: 100%;
    margin-top: 2.5rem;
    text-align: center;
    text-transform: uppercase;
  }
  .info {
    margin-left: 1rem;
    background: #c1cfda;
    width: 23rem;
    padding: 1rem;
    border-radius: var(--radius);
    h5 {
      margin: 0.5rem;
      font-size: 1.2rem;
      color: var(--clr-primary-5);
    }
  }
  span {
    font-size: 1rem;
    text-transform: capitalize;
    color: var(--clr-red-dark);
  }
  .btn {
    margin: 2rem;
  }

  .css-1ex1afd-MuiTableCell-root {
    background: beige;
    width: 2rem;
  }
  .css-1ygcj2i-MuiTableCell-root,
  .css-1ndpvdd-MuiTableCell-root {
    background: cadetblue;
    color: white;
  }
  /* .MuiPaper-root,
  .MuiPaper-elevation,
  .MuiPaper-rounded,
  .MuiPaper-elevation1,
  .MuiTableContainer-root,
  .css-13xy2my {
    width: 70% !important;
    background: red;
  } */

  .MuiTable-root,
  .css-1pclhgl-MuiTable-root {
    max-width: 95%;
    min-width: 20px;
    margin: 0 auto;
  }
  .sub {
    color: red;
    font-weight: bold;
  }
  .total {
    color: #db162f;
    font-weight: bold;
    font-size: 1rem;
  }
  @media (min-width: 900px) {
    width: 100%;
    .info {
      margin-left: 2rem;
    }
  }
`;
export default Customer;
